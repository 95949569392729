import React, { CSSProperties, FC, Fragment, useState } from 'react';
import Button, { ButtonVariant } from '../../Button';
import { Link } from 'react-router-dom';
import { ROOT_ROUTE } from '../../../AppRouter';
import { MenuItem } from '../../../entities/MenuItem';
import SvgChevronLeft from '../../../icons/SvgChevronLeft';
import SvgChevronRight from '../../../icons/SvgChevronRight';
import SvgHamburgerMenu from '../../../icons/SvgHamburgerMenu';
import SvgRoundClose from '../../../icons/SvgRoundClose';
import FlexContainer from '../../FlexContainer';
import { MobilePanel } from '../Navbar';

import NavItem from '../NavItem';
import wogaaNavLogoImage from './../../../assets/images/wogaa-nav-logo.svg';
import classNames from 'classnames/bind';
import styles from './style.module.scss';

const cx = classNames.bind(styles);

interface Props {
  menuList: MenuItem[];
}

const NavbarMobile: FC<Props> = ({ menuList }: Props) => {
  const [activePanel, setActivePanel] = useState(MobilePanel.None);

  const handleHamburgerClick = () => {
    if (activePanel !== MobilePanel.None) {
      setActivePanel(MobilePanel.None);
    } else {
      setActivePanel(MobilePanel.Main);
    }
  };

  const handlePanelClick = (event: any) => {
    const plane = event.currentTarget.getAttribute('data-path');
    setActivePanel(plane);
  };

  const handleItemClick = (menuItem: MenuItem) => {
    return () => {
      if (menuItem && menuItem.onClick) {
        menuItem.onClick();
      }
      setActivePanel(MobilePanel.None);
    };
  };

  const renderNavItem = (menuItem: MenuItem, key: string, style: CSSProperties) => {
    return (
      <NavItem
        key={key}
        onClick={handleItemClick(menuItem)}
        type={menuItem.type}
        path={menuItem.value}
        variant={menuItem.variant}
        style={style}
      >
        {menuItem.label}
      </NavItem>
    );
  };

  const renderNavDropdown = (menuItem: MenuItem, key: string) => {
    return (
      <NavItem
        key={key}
        onClick={handlePanelClick}
        type={menuItem.type}
        path={menuItem.panel}
        variant={menuItem.variant}
      >
        {menuItem.label}
        <SvgChevronRight />
      </NavItem>
    );
  };

  const renderSubPanel = () => {
    const subPanel = menuList.find((item: MenuItem) => item.panel === activePanel);
    const subPanelStyles = cx({
      Panel: true,
      SubPanel: true,
      PanelItem: true,
    });

    if (subPanel !== undefined && subPanel.dropdown !== undefined) {
      return (
        <ul className={subPanelStyles}>
          <NavItem
            key="navItemActive"
            onClick={handlePanelClick}
            path={MobilePanel.Main}
            style={{ justifyContent: 'left' }}
          >
            <SvgChevronLeft />
            <div className={styles.faded}>{subPanel.label}</div>
          </NavItem>
          {subPanel.dropdown.map((row, index) => renderNavItem(row, `navItem${index}`))}
        </ul>
      );
    }
    return null;
  };

  const menuItems = menuList.filter(item => item.type !== 'button');
  const menuButtons = menuList.filter(item => item.type === 'button');

  const renderMainPanel = () => {
    const mainPanelItems = menuItems.map((menuItem: MenuItem, index: number) =>
      menuItem.dropdown === undefined
        ? renderNavItem(menuItem, `navItem${index}`)
        : renderNavDropdown(menuItem, `navItem${index}`),
    );

    const mainPanelButtons = menuButtons.map((menuItem: MenuItem, index: number) => (
      <Button
        key={`navItem${index}`}
        onClick={handleItemClick(menuItem)}
        className="btn cursor-pointer animation-transition btn-primary-medium font-bold text-white sm"
        data-path={menuItem.value}
        variant={menuItem.variant}
      >
        {menuItem.label}
      </Button>
    ));

    return (
      <ul className={styles.Panel}>
        <div className={styles.PanelItem}>{mainPanelItems}</div>
        <div className={styles.PanelButtons}>{mainPanelButtons}</div>
      </ul>
    );
  };

  const renderPanels = () => {
    switch (activePanel) {
      case MobilePanel.Main: {
        return renderMainPanel();
      }
      default:
        return renderSubPanel();
    }
  };

  const showSgts = activePanel !== MobilePanel.Main && activePanel !== MobilePanel.Services;

  return (
    <Fragment>
      <FlexContainer alignItems="center" className={`${styles.Navbar} text-white text-base`}>
        <Link to={ROOT_ROUTE} className={styles.WogaaLogo}>
          <img src={wogaaNavLogoImage} alt="WOGAA Logo" />
        </Link>
        <div>
          {showSgts && (
            <div className={styles.sgtsContainer}>
              <span className={styles.sgtsWidget}>
                <dev-console-widget
                  variant="mobile"
                  iconColor="#666666"
                  iconWidth="24px"
                  iconHeight="24px"></dev-console-widget>
              </span>
            </div>
          )}
          <div className={styles.HamburgerContainer}>
            <a className={styles.Hamburger} onClick={handleHamburgerClick}>
              {activePanel !== MobilePanel.None ? <SvgRoundClose /> : <SvgHamburgerMenu />}
            </a>
          </div>
        </div>
      </FlexContainer>
      {renderPanels()}
    </Fragment>
  );
};

export default NavbarMobile;
