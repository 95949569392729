import classNames from 'classnames/bind';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROOT_ROUTE } from '../../../AppRouter';
import { MenuItem } from '../../../entities/MenuItem';
import FlexContainer from '../../FlexContainer';
import NavItem from '../NavItem';
import wogaaNavLogoImage from './../../../assets/images/wogaa-nav-logo-with-name.svg';
import NavDropdown from '../NavDropdown';
import { ListItem } from '../NavDropdown/NavDropdown';

import style from './style.module.scss';

const cx = classNames.bind(style);

interface Props {
  menuList: MenuItem[];
}

const NavbarDesktop: FC<Props> = ({ menuList }: Props) => {
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { pageYOffset } = window;
      setShrunk(pageYOffset > 100);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderNavItem = (menuItem: MenuItem, key: string) => {
    return (
      <NavItem
        key={key}
        onClick={menuItem.onClick}
        type={menuItem.type}
        path={menuItem.value}
        variant={menuItem.variant}
      >
        {menuItem.label}
      </NavItem>
    );
  };

  const renderNavDropdown = (menuItem: MenuItem, key: string) => {
    if (menuItem.dropdown) {
      const listItems = menuItem.dropdown.map(
        (ddItem: MenuItem, ddIndex: number): ListItem => ({
          id: `nav-dropdown-${ddIndex}`,
          menuItem: ddItem,
        }),
      );
      return (
        <div key={key} className={style.DropdownClass}>
          <NavDropdown listItems={listItems} triggerName={menuItem.label} />
        </div>
      );
    }
  };

  const renderMenu = (menuItem: MenuItem, index: number) => {
    if (menuItem && menuItem.dropdown) {
      return renderNavDropdown(menuItem, `nav-item-${index}`);
    } else {
      return renderNavItem(menuItem, `nav-item-${index}`);
    }
  };

  const classes = cx(style.Navbar, 'text-white text-base', {
    Shrunk: isShrunk,
  });

  return (
    <FlexContainer alignItems={'center'} className={classes}>
      <Link to={ROOT_ROUTE} className={style.WogaaLogo}>
        <img src={wogaaNavLogoImage} alt="WOGAA Logo" />
      </Link>
      {menuList.map(renderMenu)}
      <dev-console-widget
        variant="desktop"
        iconColor="#666666"
        iconWidth="24px"
        iconHeight="24px"></dev-console-widget>
    </FlexContainer>
  );
};

export default NavbarDesktop;
