import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Spinner from './components/Spinner/Spinner';

import Home from './pages/Home';
import NotFound from './components/NotFound';

export const ROOT_ROUTE = '/';
export const INSPECT_ROUTE = '/about/inspect';
export const SENTIMENTS_ROUTE = '/about/sentiments';
export const UPTIME_ROUTE = '/about/uptime';
export const WEB_ANALYTICS_ROUTE = '/about/web-analytics';
export const EVENTS_ROUTE = '/events';
export const FAQ_ROUTE = '/faq';
export const PRIVACY_STATEMENT_ROUTE = '/privacy-statement';

const EventsComponent = React.lazy(() => import('./pages/Events'));
const FaqComponent = React.lazy(() => import('./pages/Faq'));
const InspectComponent = React.lazy(() => import('./pages/Inspect'));
const SentimentsComponent = React.lazy(() => import('./pages/Sentiments'));
const UptimeComponent = React.lazy(() => import('./pages/Uptime'));
const WebAnalyticsComponent = React.lazy(() => import('./pages/WebAnalytics'));
const PrivacyStatementComponent = React.lazy(() => import('./pages/PrivacyStatement'));

const withSuspend = Comp => () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Comp />
    </Suspense>
  );
};

export default function AppRouter() {
  return (
    <>
      <Routes>
        <Route path={INSPECT_ROUTE} Component={withSuspend(InspectComponent)} />
        <Route path={SENTIMENTS_ROUTE} Component={withSuspend(SentimentsComponent)} />
        <Route path={UPTIME_ROUTE} Component={withSuspend(UptimeComponent)} />
        <Route path={WEB_ANALYTICS_ROUTE} Component={withSuspend(WebAnalyticsComponent)} />
        <Route path={EVENTS_ROUTE} Component={withSuspend(EventsComponent)} />
        <Route path={FAQ_ROUTE} Component={withSuspend(FaqComponent)} />
        <Route path={PRIVACY_STATEMENT_ROUTE} Component={withSuspend(PrivacyStatementComponent)} />
        <Route path={ROOT_ROUTE} Component={Home} />
        <Route path="*" Component={NotFound} />
      </Routes>
      <ScrollToTop />
    </>
  );
}
